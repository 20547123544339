/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
**/

import React from "react";
import { FormspreeProvider } from '@formspree/react';
import { ToastContainer } from 'react-toastify';

import './src/styles/reset.scss';
import './src/styles/landing.scss';
import './src/styles/animations.scss';
import 'react-toastify/dist/ReactToastify.css';

export const wrapRootElement = ({ element }) => {
  return (
    <FormspreeProvider project="1951128330807803537">
      {element}
      <ToastContainer />
    </FormspreeProvider>
  );
};
